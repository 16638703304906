<template>
  <div class="canvas-box" :class="{ fullscreen: fullscreen }">
    <div class="w-full flex flex-col justify-center h-full items-center">
      <div class="bg-gray-2000 w-96 flex flex-row text-white" v-if="concept && fullscreen" :style="'width:' + canvasW + 'px'">
        <div class="text-left pl-2">
          <svg width="139" height="92" viewBox="0 0 139 92" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-14 h-14 py-2">
            <rect x="3.5" y="3.5" width="85" height="44" fill="#282828" stroke="#FFFDFD" stroke-width="7" />
            <path d="M87.2805 73.25L110 26.4356L132.719 73.25H87.2805Z" fill="#282828" stroke="#FFFDFD" stroke-width="3" />
            <path d="M8.9263 85.75L18 62.1758L27.0737 85.75H8.9263Z" fill="#282828" stroke="#FFFDFD" stroke-width="3" />
            <path d="M64.9263 85.75L74 62.1758L83.0737 85.75H64.9263Z" fill="#282828" stroke="#FFFDFD" stroke-width="3" />
            <path d="M36.9263 80.75L46 57.1758L55.0737 80.75H36.9263Z" fill="#282828" stroke="#FFFDFD" stroke-width="3" />
            <path d="M127.5 15.5C127.5 22.9943 120.148 29.5 110.5 29.5C100.852 29.5 93.5 22.9943 93.5 15.5C93.5 8.00567 100.852 1.5 110.5 1.5C120.148 1.5 127.5 8.00567 127.5 15.5Z" fill="#282828" stroke="#FFFDFD" stroke-width="3" />
            <path d="M61.5 50.5C61.5 57.9943 54.1484 64.5 44.5 64.5C34.8516 64.5 27.5 57.9943 27.5 50.5C27.5 43.0057 34.8516 36.5 44.5 36.5C54.1484 36.5 61.5 43.0057 61.5 50.5Z" fill="#282828" stroke="#FFFDFD" stroke-width="3" />
            <path d="M90.5 57.5C90.5 64.9943 83.1484 71.5 73.5 71.5C63.8516 71.5 56.5 64.9943 56.5 57.5C56.5 50.0057 63.8516 43.5 73.5 43.5C83.1484 43.5 90.5 50.0057 90.5 57.5Z" fill="#282828" stroke="#FFFDFD" stroke-width="3" />
            <path d="M35.5 57.5C35.5 64.9943 28.1484 71.5 18.5 71.5C8.85161 71.5 1.5 64.9943 1.5 57.5C1.5 50.0057 8.85161 43.5 18.5 43.5C28.1484 43.5 35.5 50.0057 35.5 57.5Z" fill="#282828" stroke="#FFFDFD" stroke-width="3" />
          </svg>
        </div>
        <div class="flex items-center justify-center p-2 flex-grow">
          <h1 v-html="concept.title" class="text-2xl font-semibold"></h1>
        </div>
        <div class="text-right p-2 flex items-center">
          <span class="text-2xl" v-text="today()"></span>
        </div>
      </div>

      <div class="flex flex-grow w-full" ref="canvas-wrap">
        <canvas :id="'slide-canvas-' + this.id" :ref="'slide-canvas-' + this.id" class="h-full"></canvas>
      </div>

      <div class="bg-gray-2000 w-96 inline-flex h-14" :style="'width:' + canvasW + 'px'" v-if="fullscreen">
        <button class="button--toolbar text-white border-r border-gray-500 h-14" @click="gotoConcept()">
          <font-awesome-icon icon="fa-solid fa-house" class="h-6 px-2" />
        </button>

        <button class="inline-flex items-center border-r border-gray-500 text-white" @click="prevSlide()" v-if="fullscreen && showPrev">
          <font-awesome-icon icon="fa-solid fa-angle-left" class="h-6 text-white px-3" />
        </button>

        <button class="text-white inline-flex border-r border-gray-500 items-center" @click="nextSlide()" v-if="fullscreen && showNext">
          <font-awesome-icon icon="fa-solid fa-angle-right" class="h-6 text-white px-3" />
        </button>

        <div class="inline-flex justify-between w-full">
          <div class="inline-flex border-r border-gray-500 items-center">
            <div v-if="edit" class="inline-flex justify-between w-full">
              <button :style="'background-color:' + color" class="button--toolbar relative px-3 h-14 flex items-center justify-center" @click="openColorPicker = !openColorPicker">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" class="h-6" viewBox="0 0 619 551" style="enable-background: new 0 0 619 551" xml:space="preserve">
                  <path style="fill: #000" d="M531,552c-46.3,0-84-37.7-84-84c0-14.7,6.6-34.4,20.8-62c10.9-21.1,24.2-42.9,33.5-57.4l0.1-0.1c6.6-10.1,17.6-16.2,29.6-16.2s23,6,29.6,16.2l0.1,0.1c9.3,14.5,22.7,36.3,33.5,57.4c14.2,27.7,20.8,47.4,20.8,62C615,514.3,577.3,552,531,552z M235,549.5c-31,0-60.1-12.1-82-34L35.5,398c-45.2-45.2-45.2-118.8,0-164.1l77.9-78.7L46.2,88.8C26,68.5,26,35.5,46.2,15.2C56.1,5.4,69.1,0,83,0s26.9,5.4,36.8,15.2L187,82.5l39-38.9c17.4-17.4,40.5-26.9,65-26.9c24.6,0,47.7,9.6,65,27L507.4,195c17.4,17.3,27,40.5,27,65s-9.6,47.7-27,65.1L317,515.5C295.1,537.5,266,549.5,235,549.5zM397.4,288l28.1-28L291,125.5L260.6,156l35.2,35.3c20.2,20.2,20.2,53.2,0,73.5c-9.8,9.8-22.9,15.2-36.7,15.2s-26.9-5.4-36.7-15.2L187,229.6L128.6,288H397.4z" />
                  <path style="fill: #fff" d="M60.4,29.4c12.5-12.5,32.8-12.5,45.3,0l81.4,81.4l53.1-53.1c28.1-28.1,73.7-28.1,101.8,0l151.4,151.4c28.2,28.1,28.2,73.7,0,101.8L302.9,501.4c-37.5,37.5-98.3,37.5-135.8,0L49.6,383.9c-37.5-37.5-37.5-98.3,0-135.8l92.1-93L60.4,74.6C47.9,62.1,47.9,41.9,60.4,29.4L60.4,29.4z M236.4,250.6L187,201.3l-92.1,92.1c-4.2,4.2-7,9.2-8.4,14.6h319.2l42.4-42.3c3.1-3.2,3.1-8.2,0-11.4L296.7,102.9c-3.2-3.1-8.2-3.1-11.4,0l-53,53.1l49.3,49.4c12.5,12.5,12.5,32.7,0,45.2S248.9,263.1,236.4,250.6L236.4,250.6z M467,468c0-25.2,32.6-79.6,51.2-108.7c6.1-9.4,19.5-9.4,25.6,0C562.4,388.4,595,442.8,595,468c0,35.3-28.7,64-64,64S467,503.3,467,468L467,468z" />
                </svg>
                <vue-tailwind-color-picker v-model="color" @change="changedColor" v-if="openColorPicker" class="absolute z-10 bottom-10" />
              </button>

              <button class="button--toolbar px-3 text-white" @click="addText()">
                <font-awesome-icon icon="fa-solid fa-font-case" class="cursor-pointer h-6" />
              </button>
              <button class="button--toolbar px-3 text-white" @click="addBlank">
                <svg class="h-6" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 50 40" style="enable-background: new 0 0 50 40" xml:space="preserve">
                  <g>
                    <path style="fill: #00cee1" d="M10,0h30c5.5,0,10,4.5,10,10v20c0,5.5-4.5,10-10,10H10C4.5,40,0,35.5,0,30V10C0,4.5,4.5,0,10,0z" />
                    <path style="fill: none; stroke: #000000" d="M10,0.5h30c5.2,0,9.5,4.3,9.5,9.5v20c0,5.2-4.3,9.5-9.5,9.5H10c-5.2,0-9.5-4.3-9.5-9.5V10C0.5,4.8,4.8,0.5,10,0.5z" />
                  </g>
                </svg>
              </button>
              <button class="button--toolbar px-3 text-white" @click="addLine">
                <font-awesome-icon icon="fa-solid fa-pen-line" class="cursor-pointer h-5" :class="isDrawing ? 'text-white' : ''" />
              </button>
              <button class="button--toolbar px-3 text-white" @click="addSLine">
                <font-awesome-icon icon="fa-solid fa-horizontal-rule" class="cursor-pointer h-5" :class="isDrawing ? 'text-white' : ''" />
              </button>
              <button class="button--toolbar px-3 text-white" @click="addRect">
                <svg version="1.1" id="Layer_1" class="h-6" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 510.2 384" style="enable-background: new 0 0 510.2 384" xml:space="preserve"><rect style="fill: #ffffff" width="510.2" height="384" /></svg>
              </button>
              <button class="button--toolbar px-3 text-white" @click="addTriangle">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" class="h-6" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 456 390" style="enable-background: new 0 0 456 390" xml:space="preserve"><path style="fill: #ffffff" d="M430.4,340.8c5.9,9.9,25.6,47.2,25.6,47.2s-41.8,1.1-53.3,1.1H56.5C45,389.1,0,390,0,390s23-39.3,28.9-49.2L202,45.9C207.7,36.1,229.2,0,229.2,0s22.3,36.1,28.1,45.9L430.4,340.8z" /></svg>
              </button>
              <button class="button--toolbar px-3 text-white" @click="addCircle">
                <font-awesome-icon icon="fa-solid fa-circle" class="cursor-pointer h-6" />
              </button>
            </div>
          </div>
          <div v-if="textSelected" class="inline-flex items-center text-xs">
            <label for="fontSize" class="text-white pr-2">Font size</label>
            <BaseSelect id="fontSize" classes="text-xs" name="fontSize" :options="fontSizes" v-model="fontSize" optionKey="size" optionValue="name" />
          </div>
          <div v-if="blankSelected" class="inline-flex items-center text-xs">
            <button class="button--toolbar px-3 text-white" @click="hideBlankObject()">
              <font-awesome-icon icon="fa-solid fa-eye-slash" class="cursor-pointer h-6" />
            </button>
            <button class="button--toolbar px-3 text-white" @click="showBlankObject()">
              <font-awesome-icon icon="fa-solid fa-eye" class="cursor-pointer h-6" />
            </button>
          </div>
          <div class="inline-flex border-l border-gray-500" v-if="edit">
            <button class="button--toolbar w-12" @click="removeObj">
              <font-awesome-icon icon="fa-solid fa-trash" class="cursor-pointer h-6 text-red-1" />
            </button>
            <button class="button--toolbar w-12" @click="saveCanvasToJson" v-if="edit">
              <font-awesome-icon icon="fa-regular fa-floppy-disk" class="cursor-pointer h-6 text-white" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { fabric } from "fabric";
import VueTailwindColorPicker from "@/components/VueTailwindColorPicker";
import SlideService from "@/services/SlideService";
import BaseSelect from "@/components/BaseSelect";
import { component } from "vue-fullscreen";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFloppyDisk, faFillDrip, faClose } from "@fortawesome/pro-regular-svg-icons";
import { faFontCase, faRectangle, faCircle, faTriangle, faAngleLeft, faAngleRight, faEyeSlash, faTrash, faPenLine, faHouse, faHorizontalRule, faEye } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faFontCase, faRectangle, faCircle, faTriangle, faTrash, faFloppyDisk, faFillDrip, faAngleLeft, faAngleRight, faClose, faEyeSlash, faPenLine, faHouse, faHorizontalRule, faEye);

export default {
  data() {
    return {
      canvas: null,
      text: "Text",
      color: null,
      openColorPicker: false,
      error: "",
      concept_id_next: "",
      nextId: "",
      showNext: true,
      showPrev: true,
      isDrawing: false,
      canvasW: 0,
      textSelected: false,
      blankSelected: false,
      prevId: '',
      fontSize: 16,
      fontSizes: [
        { size: 24, name: "Standard" },
        { size: 16, name: "Small" },
        { size: 30, name: "Large" },
      ],
    };
  },
  watch: {
    fontSize: {
      handler: function (val) {
        this.changedTextSize(val);
      },
    },
  },
  components: {
    VueTailwindColorPicker,
    VueFullscreen: component,
    FontAwesomeIcon,
    BaseSelect,
  },
  props: {
    id: Number,
    content: String,
    index: Number,
    edit: Boolean,
    fullscreen: Boolean,
    concept_id: Number,
  },
  mounted() {
    this.getConcept();
    this.getSlides();
    this.loadCanvas();

    if (!this.edit) {
      this.disableEdit();
    }

    this.concept_id_next = this.concept_id;

    //console.log("concept", this.concept);

    //console.log("this.loadedSlides", this.loadedSlides);
    if (this.loadedSlides) {
      //console.log("loaded");
      //this.checkNext();
      //this.checkPrev();
    }
  },
  created() {
    window.addEventListener("resize", this.canvasWidth);
  },
  destroyed() {
    window.removeEventListener("resize", this.canvasWidth);
  },
  computed: {
    ...mapState("concept", ["concept", "slides", "loadingSlides", "loadedSlides"]),
    ...mapGetters("topic", ["loading", "concepts", "topic", "meta", "links"]),
  },
  methods: {
    canvasWidth() {
      this.canvasW = this.$refs["slide-canvas-" + this.id].offsetWidth;
      //console.log("width", this.$refs["slide-canvas-" + this.id].offsetWidth);
    },
    canvasWidthLoad() {
      this.canvasW = this.$refs["slide-canvas-" + this.id].offsetWidth + 102;
    },
    today() {
      let currentDate = new Date();
      let cDay = currentDate.getDate();
      let cMonth = currentDate.getMonth() + 1;
      let cYear = currentDate.getFullYear();
      return cDay + "/" + cMonth + "/" + cYear;
    },
    loadCanvas() {
      this.canvas = new fabric.Canvas("slide-canvas-" + this.id);

      if (this.content != "") {
        this.canvas.loadFromJSON(this.content);
      }

      var canvas = this.canvas;

      fabric.loadSVGFromString('<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 639 414.8" style="enable-background:new 0 0 639 414.8;" xml:space="preserve"><g><path style="fill:#00D0DF;stroke:#020202 stroke-miterlimit:10;" d="M341.4,14.3V5.9c0-3-2.4-5.4-5.4-5.4h-22.9c-3,0-5.4,2.4-5.4,5.4v8.4H-1.5v402h644v-402L341.4,14.3L341.4,14.3z"/><polyline style="fill:#5A5A5A;" points="319.9,12.7 324.5,4 329.1,12.7 	"/></g></svg>', function (objects, options) {
        var loadedObject = fabric.util.groupSVGElements(objects, options);
        // Set sourcePath
        loadedObject.top = 20;

        loadedObject.set({
          lockMovementX: true,
          lockScalingX: true,
          lockScalingY: true,
          lockRotation: true,
          hasControls: false,
          hasBorders: false,
          excludeFromExport: true,
          top: 380,
          left: -1,
          editable: true,
        });

        loadedObject.toObject = (function (toObject) {
          return function (propertiesToInclude) {
            return fabric.util.object.extend(toObject.call(this, propertiesToInclude), {
              editable: true,
            });
          };
        })(loadedObject.toObject);

        canvas.add(loadedObject);

        canvas.renderAll();
      });

      this.canvas.setHeight(400);
      this.canvas.setWidth(640);

      this.canvas.setBackgroundColor("#00cee1");
      this.canvas.renderAll();

      //this.addBlank();

      this.canvas.on("selection:created", (e) => {
        console.log("selection:created", e.target);

        if (e.target.text) {
          this.textSelected = true;
        } else {
          this.textSelected = false;
        }

        if (e.target.blankObject) {
          this.blankSelected = true;
          this.hideBlankObject();
        } else {
          this.blankSelected = false;
        }

        this.color = e.target.fill;

        this.canvas.bringToFront(e.target);
        this.canvas.renderAll();
      });

      this.canvas.on("selection:updated", (e) => {
        console.log("selection:updated", e.target);

        if (e.target.text) {
          this.textSelected = true;
        } else {
          this.textSelected = false;
        }

        if (e.target.blankObject) {
          this.blankSelected = true;
          this.hideBlankObject();
        } else {
          this.blankSelected = false;
        }

        this.color = e.target.fill;
      });

      this.canvas.on("selection:cleared", () => {
        this.openColorPicker = false;
        this.textSelected = false;
        this.blankSelected = false;
      });

      this.canvasWidth();
    },
    gotoConcept() {
      this.$router.push(`/concept/${this.concept_id}#slide-${this.id}`);
    },
    disableEdit() {
      this.canvas.forEachObject(function (obj) {
        //console.log('obj', obj);
        if (!obj.editable) {
          obj.selectable = false;
        }
      });
    },
    setFullScreen() {
      this.fullscreen = !this.fullscreen;
    },
    addRect() {
      const rect = new fabric.Rect({
        top: 100,
        left: 100,
        width: 70,
        height: 70,
        fill: "#FF0000",
      });

      rect.set({
        hasRotatingPoint: false,
      });

      this.canvas.add(rect);
      this.canvas.setActiveObject(rect);
    },
    addBlank() {
      var shadow = new fabric.Shadow({
        color: "black",
        blur: 5,
      });

      const rect = new fabric.Rect({
        top: 100,
        left: 100,
        width: 20,
        height: 15,
        fill: "#00cee1",
        stroke: "#000",
        strokeWidth: 1,
        editIt: true,
        blankObject: true,
        rx: 3,
        ry: 3,
        objectCaching: false,
      });

      rect.set({
        hasRotatingPoint: false,
      });

      rect.on("scaling", function () {
        this.set({
          width: this.width * this.scaleX,
          height: this.height * this.scaleY,
          scaleX: 1,
          scaleY: 1,
        });
      });

      rect.toObject = (function (toObject) {
        return function (propertiesToInclude) {
          return fabric.util.object.extend(toObject.call(this, propertiesToInclude), {
            blankObject: true,
          });
        };
      })(rect.toObject);

      this.canvas.add(rect);
    },
    addCoverSVG() {
      this.canvas.loadSVGFromString();
    },
    changedColor() {
      this.canvas.getActiveObject().set("fill", this.color);
      this.canvas.renderAll();
      //console.log("this.canvas", this.canvas);
    },
    hideBlankObject() {
      this.canvas.getActiveObject().set("fill", null);
      this.canvas.getActiveObject().set("hidden", true);
      this.canvas.renderAll();
    },
    showBlankObject() {
      this.canvas.getActiveObject().set("fill", "#00cee1");
      this.canvas.renderAll();
    },
    changedTextSize(fontSize) {
      this.canvas.getActiveObject().set("fontSize", fontSize);
      this.canvas.renderAll();
    },
    addCircle() {
      const rect = new fabric.Circle({
        top: 100,
        left: 100,
        radius: 50,
        fill: "#0000FFFF",
      });

      rect.set({
        hasRotatingPoint: false,
      });

      this.canvas.add(rect);
      this.canvas.setActiveObject(rect);
    },
    addTriangle() {
      const triangle = new fabric.Triangle({
        top: 100,
        left: 100,
        fill: "#89CA36FF",
      });

      triangle.set({
        hasRotatingPoint: false,
      });

      this.canvas.add(triangle);
      this.canvas.setActiveObject(triangle);
    },
    draw(e) {
      if (!this.painting) return;
      this.ctx.lineWidth = 10;
      this.ctx.lineCap = "round";

      this.ctx.lineTo(e.clientX, e.clientY);
      this.ctx.stroke();

      this.ctx.beginPath();
      this.ctx.moveTo(e.clientX, e.clientY);
    },
    addSLine() {
      this.canvas.add(
        new fabric.Line([50, 100, 200, 200], {
          left: 170,
          top: 150,
          stroke: "black",
        })
      );
    },
    addLine() {
      this.isDrawing = !this.isDrawing;

      this.canvas.isDrawingMode = this.isDrawing;
    },
    addText() {
      // const text = new fabric.Textbox()
      // text.stroke = '#fff'
      // text .strokeWidth = 2
      // text のlineheight
      // text.lineHeight = 0.5
      const text = new fabric.Textbox(this.text, {
        top: 100,
        left: 100,
        fontSize: 24,
      });
      text.set({
        hasRotatingPoint: false,
        fontFamily: "Mulish",
      });
      this.canvas.add(text);
      this.canvas.setActiveObject(text);
      this.canvas.requestRenderAll();
    },
    removeObj() {
      const obj = this.canvas.getActiveObject();

      if (obj) {
        this.canvas.remove(obj);
      }
    },
    clearCanvas() {
      const objects = this.canvas.getObjects();
      if (objects.length > 0) {
        this.canvas.clear();
      }
    },
    saveCanvasToJson() {
      SlideService.update({ id: this.id, concept_id: this.$route.params.concept_id, content: this.canvas.toJSON() })
        .then(() => {
          this.message = "Slide updated.";
          this.$eventBus.$emit("slide:updated", true);
        })
        .catch((error) => (this.error = getError(error)));
    },
    loadImage(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (f) => {
        const data = f.target.result;
        fabric.Image.fromURL(data, (img) => {
          const to_scale = this.getScale(img);
          const image = img.scale(to_scale).set({
            top: this.canvas.height / 2,
            left: this.canvas.width / 2,
            originX: "center",
            originY: "center",
            hasRotatingPoint: false,
          });
          this.canvas.add(image).renderAll();
        });
      };
      reader.readAsDataURL(file);
    },
    getScale(img) {
      const per_w = img.width / this.canvas.width;
      const per_h = img.height / this.canvas.height;
      const per_diff = per_w - per_h;

      if (img.width >= this.canvas.width || img.height >= this.canvas.height) {
        if (per_diff > 0) {
          return (1 / per_w) * 0.95;
        } else {
          return (1 / per_h) * 0.95;
        }
      }

      return 0.9;
    },
    async getSlides() {
      await this.$store.dispatch("concept/getSlides", this.concept_id).then(() => {
        //console.log(this.slides);
        this.checkNext();
        this.checkPrev();
      });
    },
    async nextSlide() {
      //const nextId = this.checkNext();
      await this.checkNext()

      if (this.edit) {
        window.location = `/concept/${this.concept_id_next}/slide-edit/${this.nextId}`;
      } else {
       console.log('next lside', `/concept/${this.concept_id_next}/slide/${this.nextId}`);
        //window.location = `/concept/${this.concept_id_next}/slide/${this.nextId}`;
      }
    },
    async checkNext() {
      await this.$store.dispatch("topic/getConcepts", this.concept.topic_id);

      console.log("this.concepts", this.concepts);

      let index = this.slides.findIndex((element) => {
        if (element.id === this.id) {
          return true;
        }
      });

      let concept_index = this.concepts.findIndex((element) => {
        if (element.id === this.concept.id) {
          return true;
        }
      });

      if (this.slides[index + 1]) {
        this.nextId = this.slides[index + 1].id;
        this.concept_id_next = this.concepts[concept_index].id;
      } else {
        if (this.concepts[concept_index + 1]) {
          this.nextId = this.concepts[concept_index + 1].slides[0].id;
          this.concept_id_next = this.concepts[concept_index + 1].id;
        } else {
          this.showNext = false;
        }
      }
    },
    async prevSlide() {
      await this.checkNext()

      if (this.edit) {
        window.location = `/concept/${this.concept_id}/slide-edit/${this.prevId}`;
      } else {
        window.location = `/concept/${this.concept_id}/slide/${this.prevId}`;
      }
    },
    async checkPrev() {
      await this.$store.dispatch("topic/getConcepts", this.concept.topic_id);

      console.log("this.concepts", this.concepts);

      let index = this.slides.findIndex((element) => {
        if (element.id === this.id) {
          return true;
        }
      });

      let concept_index = this.concepts.findIndex((element) => {
        if (element.id === this.concept.id) {
          return true;
        }
      });

      if (this.slides[index - 1]) {
        this.prevId = this.slides[index - 1].id;
        this.concept_id_next = this.concepts[concept_index].id;
      } else {
        if (this.concepts[concept_index - 1]) {
          this.prevId = this.concepts[concept_index - 1].slides[0].id;
          this.concept_id_next = this.concepts[concept_index - 1].id;
        } else {
          this.showPrev = false;
        }
      }
    },
    async getConcept() {
      await this.$store.dispatch("concept/getConcept", this.concept_id);
    },
  },
};
</script>

<style>
canvas {
  border: 1px solid #ccc;
}
.canvas-container {
  margin: 0 auto;
}
</style>
