<template>
  <div>
    <label
      :for="name"
      class="text-gray-500"
      :class="{ 'sr-only': !showLabel }"
      v-if="label"
    >
      {{ label }}
    </label>
    <select
      :id="name"
      :name="name"
      :type="type"
      v-model="selected"
      :required="required"
      @input="updateValue"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :class="classes"
      class="
        block
        w-full
        px-3
        py-2
        pr-8
        placeholder-gray-400
        border border-gray-300
        rounded-md
        appearance-none
        focus:outline-none focus:ring-blue-500 focus:border-blue-500
      "
    >
    <option :value="option[optionKey]" v-for="(option, i) in options" :key="i" >{{ option[optionValue]  }}</option>
    </select>
  </div>
</template>

<script>
export default {
  name: "BaseSelect",
  watch: {
    value: {
      handler: function (value) {
        this.selected = value
      },
      immediate: true
    },
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    optionKey: {
      type: String,
      default: "key",
    },
    optionValue: {
      type: String,
      default: "value",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    options: {
      type: Array,
      default() {
          return []
      }
    },
    type: {
      type: String,
      default: "text",
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    autocomplete: {
      type: String,
      default: null,
    },
    classes: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selected: ''
    }
  },
  methods: {
    updateValue(event) {
      return this.$emit("input", event.target.value);
    },
  },
  created() {
    
    this.selected = this.value

    //console.log('this.value', this.value);
  }
};
</script>
